body {
  font-family: "Roboto", "Franklin Gothic Medium", Tahoma, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input-affix-wrapper input {
  border-radius: 0;
}

.ant-input-number-handler-wrap {
  border-radius: 0 20px 20px 0;
}

.ant-radio-wrapper span:not(:first-child) {
  padding-left: 4px;
  padding-right: 0;
}

.ant-input:focus,
.ant-input-focused {
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}

.ant-notification-bottomRight {
  pointer-events: none;

  inset: 0 0 0 auto !important;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
}

.ant-notification-bottomRight * {
  pointer-events: all;
}

.ant-notification-notice-description > * {
  margin: 0;
}

.ant-tabs:has(
    > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane
      > .ant-card
  )
  > .ant-tabs-nav {
  margin-bottom: 0;
}
.ant-tabs:has(
    > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane
      > .ant-card
  )
  > .ant-tabs-nav::before {
  border-bottom: 0;
}
.ant-tabs
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane
  > .ant-card {
  border-top-left-radius: 0;
  margin-top: -1px;
}

/* override ul styling that antd assumes is reset */
.ant-picker-footer > ul.ant-picker-ranges {
  margin-top: unset;
}

/* hide extra margin antd adds after the last item in a TimePicker column */
.ant-picker-time-panel .ant-picker-time-panel-column:after {
  display: none;
}

/* make it less likely for the settings/logout menu to disappear when hovered quickly */
div .slide-down-enter,
div .slide-up-enter {
  animation-duration: 0s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hub-animate-loading {
  animation: 1s linear infinite spin;
}

.ant-select-item-empty:has(.hub-hide-parent) {
  display: none;
}

.hub-md-inline p {
  margin: 0;
}
